import { Menu } from './menu.model';

export const horizontalMenuItems = [
    new Menu (1, 'Home', '/', null, null, false, 0),
    new Menu (2, 'Nosotros', null, null, null, true, 0),  
        new Menu (21, 'Misión Visión', '/about', null, null,false, 2), 
        new Menu (22, 'Políticas de Privacidad', '/politicas-de-privacidad', null, null, false, 2), 

    new Menu (3, 'Servicios', '/agents', null, null, false, 0),     
    // new Menu (40, 'Pages', null, null, null, true, 0),
    new Menu (4, 'Educación Tributaria', '/educaciontributaria', null, null, false, 0),     
    // new Menu (42, 'Tansparencia', '/agents/1', null, null, false, 40),
    // new Menu (43, 'Login', '/login', null, null, false, 40), 
    // new Menu (44, 'Register', '/register', null, null, false, 40), 
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'Pricing', '/pricing', null, null, false, 40), 
    // new Menu (47, 'Terms & Conditions', '/terms-conditions', null, null, false, 40), 
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    
    new Menu (60, 'Transparencia', '/transparencia', null, null, false, 0),
    // new Menu (70, 'Programas', '/about', null, null, false, 0),   
    new Menu (80, 'Contáctanos', '/contact',null, null, false, 0),

    new Menu (90, 'Consultas', '/consultas', null, null, false, 0),

    new Menu (90, 'Convocatorias', '/convocatorias', null, null, false, 0),
    
    // new Menu (100, 'Convocatorias', null, null, null, true, 0),
    // new Menu (141, 'External Link', null, 'http://themeseason.com', '_blank', false, 140), 
    // new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    // new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    // new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140)    
]

export const verticalMenuItems = [
    new Menu (1, 'Home', '/', null, null, false, 0),
    new Menu (2, 'Nosotros', '/about', null, null, false, 0),  //properties
    new Menu (3, 'Servicios', '/agents', null, null, false, 0),     
    // new Menu (40, 'Pages', null, null, null, true, 0),
    new Menu (4, 'Educación Tributaria', '/educaciontributaria', null, null, false, 0),     
    // new Menu (42, 'Tansparencia', '/agents/1', null, null, false, 40),
    // new Menu (43, 'Login', '/login', null, null, false, 40), 
    // new Menu (44, 'Register', '/register', null, null, false, 40), 
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'Pricing', '/pricing', null, null, false, 40), 
    // new Menu (47, 'Terms & Conditions', '/terms-conditions', null, null, false, 40), 
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    
    new Menu (60, 'Transparencia', '/transparencia', null, null, false, 0),
    // new Menu (70, 'Programas', '/about', null, null, false, 0),   
    new Menu (80, 'Contáctanos', '/contact',null, null, false, 0),

    new Menu (90, 'Consultas', '/consultas', null, null, false, 0),

    new Menu (90, 'Convocatorias', '/convocatorias', null, null, false, 0),
    
    // new Menu (100, 'Convocatorias', null, null, null, true, 0),
    // new Menu (141, 'External Link', null, 'http://themeseason.com', '_blank', false, 140), 
    // new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    // new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    // new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140)    
]
