import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DeudaFiltrada } from 'src/app/modelos/consultas/consultas';
import { EstadoCuentaPublicService } from 'src/app/servicios/public/estadoCuentaPublic.service';
import { Subject } from 'rxjs';



export interface Item{
  id : number,
  accion : boolean
}

@Component({
  selector: 'app-listDeudas',
  templateUrl: './listDeudas.component.html',
  styleUrls: ['./listDeudas.component.scss']
})

export class ListDeudasComponent implements OnInit {

  @Output() onCarrito = new EventEmitter<Item>();
  gestionDeuda$ : Subject<DeudaFiltrada[]> = new Subject<DeudaFiltrada[]>()  

  public deudaFiltrada: DeudaFiltrada[]

  title : string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public estadoCuentaPublicService: EstadoCuentaPublicService) {

    this.deudaFiltrada=this.estadoCuentaPublicService.deudaFiltrada


    this.gestionDeuda$ = _data.subject$
    this.title = _data.title

    //Suscribimos al proceso de deuda filtrada desde el padre
    this.gestionDeuda$.subscribe(
      {
        next : (data)=>{
          this.deudaFiltrada = data
        }
      }
    )

  }

  
  onGestionDeuda(id : number, accion : boolean)
  {
    let data : Item

    data = {id,accion}

    //COmpartimos información con el padre
    this.onCarrito.emit(data)

  }
  ngOnInit() {}


}
