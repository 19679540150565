
export class BusquedaContri {
	personaId				: number;
	tipoDocIdentidadId	: number;
	documento				: string;
	nroDocuIdentidad		: string;
	contribuyente			: string;
	direccionCompleta		: string;
}

export class EstadoCuenta {

	anio				: number;
	tributo				: string;
	descripcion			: string;
	cuotas				: string;
	insoluto			: number;
	emision				: number;
	reajuste			: number;
	descuento			: number;
	interes				: number;
	conceptoId			: number;
	subconceptoId		: number;
	concepto			: string;
	determinacionId		: number;
	deudaId				: number;
	predioId			: number;
	placa				: string;
	nroPapeleta			: string;
	fechaInfraccion		: Date;
	infraccion			: string;
	fechaVencimiento	: Date;
	totalDeuda1			: number; //total_deuda
	infoDescuentoWEB	:string;

	//Agregados
	agregadoCarrito 	: boolean;
	activardescuento 	: number; 	//Nos indica si se le debe aplicar el descuento, según lo seleccionado.
									//Valores 1 o 0
	totalDeuda2			: number //totalDeuda

}


export class FileData {
	name	: string;
	size	: string;
	url 	: string
  }

export class TdTipoTramite {
	tipoTramiteId		 : number;
	descCorta			 : string;
	descripcion			 : string;
	usuarioId			 : number;
	estado			 	 : string;
	fechaRegistro		 : Date;
	terminal			 : string;

}


export class TdExpedienteWeb {
	idExpediente		: number;
	tipoTramiteId		: number;
	tipoTramite		: string;
	docIdentidad		: string;
	contribuyente		: string;
	nroCelular			: string;
	correo			 	: string;
	descripdion			: string;

}

export class TdExpedienteWebFiles {
	idFile			: number;
	idExpediente	: number;
	fileNames		: string;

}

export class Infractor {
	personaId : number;
	tipoDocumentoent : string;
	nroDocuIdentidad : string;
	apellidosNombres : string
}


export class RecordInfractor {
	item					: number;
	fechaInfraccion		: Date;
	placaAnterior			: string;
	nombreInfractor		: string;
	tipoDocIdentidad		: string;
	nroDocIdentidad		: string;
	papeletaId				: number;
	placa					: string;
	nroPapeleta			: string;
	infraccion				: string;
	nivelGravedad			: string;
	puntosEnProc			: number;
	puntosFirmes			: number;
	reincidente				: number;
	estadoPapeleta			: string;
	numeroResolucion		: string;
	estadoResolucion		: string;
	personaInfractorId	: number;	
	deudaId				: number;
	montoMulta				: number;
	descuento				: number;
	totalConDscto			: number;
	fechaHoraActual		: Date;


}

export class RecordVehiculo {

	item						: number;
	fecha						: Date;
	hora						: Date;
	placaAnterior				: string;
	propietario					: string;
	direccion					: string;
	marca						: string;
	modelo						: string;
	papeletaId					: number;
	placa						: string;
	nroPapeleta				: string;
	fechaInfraccion			: Date;
	nombreInfractor			: string;
	nombrePropietario			: string;
	direccionPropietario		: string;
	infraccion					: string;
	nivelGravedad				: string;
	puntosAcum					: number;
	reincidente					: number;
	estadoPapeleta				: string;
	numeroResolucion			: number;
	estadoResolucion			: string;
	personaInfractorId		: number;
	fechaInfraccionDate		: Date;
	deudaId					: number;
	montoMulta					: number;
	descuento					: string;
	totalConDscto				: string;
	fechaHoraActual			: Date;


}


export class MpActualizaDatosWeb {
	idDato			 : number;
	docIdentidad	 : string;
	contribuyente	 : string;
	nroCelular		 : string;
	fonoFijo		 : string;
	correo			 : string;
	domicilio		 : string;

}


export class  Menu {
	name	: string;
	href	: string;
	icon	: string;
	current	: boolean
  }


export class DeudaFiltrada {
	deudaId: number;
	periodo: number;
	tributo: string;
	referencia: string;
	cuota: string;
	deuda: number;
	descuento: number;
	deudaSelect: number;
  }

  export class ConvocatoriasHead {
	idConvocatoriaHead : Number;
	head				: String;
	link				: String;
	periodo				: String;
	fecha				: Date;
	activo				: boolean;
	
  }


  export class Convocatorias {
	idConvocatoria		: number;
	codigo				: string;
	cargo				: string;
	dependencia			: string;
	aptosEntrevista		: string;
	resultadoFinal		: string;
	fechaConvocatortia	: Date;
	activo				: boolean;
	perfil				: string;
	comunicado			: string;
  }

  export class Contactos{

	
	idContacto			: number;
	titulo		: string;
	subtitulo		: string;
	fono1		: string;
	fono2		: string;
	fono3		: string;
	fono4		: string;
	fono5		: string;
	activo		: string;


  }
