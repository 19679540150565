import { Injectable } from '@angular/core';
import { EstadoCuenta, BusquedaContri,DeudaFiltrada } from '../../modelos/consultas/consultas';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { apiMainService } from '../api.main.service';

// @Injectable({
//   providedIn: 'root'
// })


export class EstadoCuentaPublicService extends apiMainService {

  //Si ses ta buscando información desde el home
  public isConsultaRapida : boolean = false;
  //ID tpo de búsuqueda en CONSULTR
  public idtipoBusquedaSelected : number
  public valorConsultaRapida : string
  ////

  public contriConsultar : BusquedaContri = new BusquedaContri();
  public placa : string =''; 
  public deudaFiltrada: DeudaFiltrada[]
  

  

  
}
