import { Injectable } from '@angular/core';
import { RecordVehiculo,RecordInfractor,TdTipoTramite, Infractor, MpActualizaDatosWeb, Contactos, ConvocatoriasHead } from 'src/app/modelos/consultas/consultas';
import { EstadoCuenta, BusquedaContri,TdExpedienteWeb,Convocatorias } from '../../modelos/consultas/consultas';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { apiMainService } from '../api.main.service';

// @Injectable({
//   providedIn: 'root'
// })


export class ConsultasService extends apiMainService {
  
  //Contactos
  getContactos$(): Observable<Contactos[]> {
    return this.http.get<Contactos[]>(this.url_api + '/api/web/CfContactoes/', { headers: this.httpOptions.headers,withCredentials: false });    
  }
  
  //Convocatorias
  getConvocatoriashead$(periodo): Observable<ConvocatoriasHead[]> {
    return this.http.get<ConvocatoriasHead[]>(this.url_api + '/api/web/RhConvocatoriaHeads/'+periodo.toString(), { headers: this.httpOptions.headers,withCredentials: false });    
  }

  //Convocatorias
  getConvocatorias$(): Observable<Convocatorias[]> {
    return this.http.get<Convocatorias[]>(this.url_api + '/api/web/RhConvocatorias/', { headers: this.httpOptions.headers,withCredentials: false });    
  }
  

  //get TipoTramite  All
  allTipoTramite$(): Observable<TdTipoTramite[]> {
    return this.http.get<TdTipoTramite[]>(this.url_api + '/api/TdTipoTramites/web/', { headers: this.httpOptions.headers,withCredentials: false });    
  }

  //
  postExpedienteWeb$(expediente : TdExpedienteWeb) : Observable<TdExpedienteWeb> {
    return this.http.post<TdExpedienteWeb>(this.url_api + '/api/web/TdExpedienteWebs/',expediente,{headers : this.httpOptions.headers,withCredentials: false} )
  }

  //
  postActualziaDatos$(datos : MpActualizaDatosWeb) : Observable<MpActualizaDatosWeb> {
    return this.http.post<MpActualizaDatosWeb>(this.url_api + '/mpactualizadatosweb/',datos,{headers : this.httpOptions.headers,withCredentials: false} )
  } 


  fonodisponible(idUnidad : number):Observable<any>
  {    
    let datos ={
      idunidad : idUnidad,      
    }
    
    return this.http.post<any>(this.url_api + '/api/web/generic/GenericWeb/getFonodisponible/',datos , { headers: this.httpOptions.headers,withCredentials :false });
  }


  busquedaInfractor(tipo_busqueda : number, valor : string  ):Observable<Infractor[]>
  {

    let datos ={
      tipobusqueda          : tipo_busqueda, //Búsqueda por Apellidos y nombres.
      valor                 : valor
    }
    
    return this.http.post<Infractor[]>(this.url_api + '/api/web/generic/GenericWeb/busquedainfractor/',datos, { headers: this.httpOptions.headers });
  }


  recordInfractor$( idPersona : number ):Observable<RecordInfractor[]>
  {

    let datos ={
      idPersona : idPersona,
      usuarioid : 79
    }
    
    return this.http.post<RecordInfractor[]>(this.url_api + '/api/web/generic/GenericWeb/recordInfractor/',datos, { headers: this.httpOptions.headers });

  }


  recordVehiculo$( placa : string ):Observable<RecordVehiculo[]>
  {

    let datos ={
      placa          : placa,
      usuarioId :79
    }
    
    return this.http.post<RecordVehiculo[]>(this.url_api + '/api/web/generic/GenericWeb/recordvehiculo/',datos, { headers: this.httpOptions.headers });

  }

  busquedaContri(tipo_busqueda : number,tipo_doc_identidad_id : number, valor : string  ):Observable<BusquedaContri[]>
  {
    /*
    Realiza bpusquedas de contribuyente por @tipo_busqueda,
    @tipo_busqueda=1, por DNI o RUC.
    @tipo_busqueda=2, por Código de contribuyente
    @tipo_busqueda=3, por Apellidos y nombres o razón Social.
    */

    let datos ={
      tipobusqueda          : tipo_busqueda,
      tipodocidentidadid    : tipo_doc_identidad_id,
      valor                 : valor
    }
    
    return this.http.post<BusquedaContri[]>(this.url_api + '/api/web/generic/GenericWeb/buscaquedaContri/',datos , { headers: this.httpOptions.headers });

  }

  estadoCuenta(idPersona : number):Observable<EstadoCuenta[]>
  {
    let datos ={
      idpersona : idPersona,      
      subconceptos: "",
      annios :"",
      tipogrupo :0

    }
    
    return this.http.post<EstadoCuenta[]>(this.url_api + '/api/web/generic/GenericWeb/estadocuentaweb/',datos , { headers: this.httpOptions.headers });     
  }



  // resetPassword(email : string, password : string ):Observable<boolean>
  // {
  //   password=crypto.SHA512(email + password).toString();

  //   let datos ={
  //     email : email,
  //     password: password
  //   }    

  //   //Cambiar la contraseña
  //   return this.http.post<boolean>(this.url_api + '/resetpassword/',datos, { headers: this.httpOptions.headers });     


  // }
}
