import { Component } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';

import {ConfigService} from '../app/servicios/config/config.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   
  public settings: Settings;

  constructor(
    private configService : ConfigService,
    public appSettings:AppSettings, public router:Router
    )
    {
    this.settings = this.appSettings.settings;

    //Persoalizamos el tipo de header [image,carousel,default]
    this.configService.getHome$().subscribe(
      (data)=>{
        if (data && data.length>0) this.settings.header = data[0].headerTypes        
      },

      (error)=>{this.settings.header = 'image'}
      
    )

    // public headerTypes = ['default', 'image', 'carousel'];
    // window.scrollTo(0,0);
    // this.router.navigate(['/']);
    
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          window.scrollTo(0,0);
        }); 
      }            
    });    
  }

}
