import { Component, OnInit, Input } from '@angular/core';
import { ConsultasService } from '../../../servicios/consultas/consultas.service';
import { EstadoCuenta, RecordInfractor, RecordVehiculo } from '../../../modelos/consultas/consultas';
import { EstadoCuentaPublicService } from '../../../servicios/public/estadoCuentaPublic.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Data } from 'src/app/app.service';

import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { ElementRef } from '@angular/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

export interface DeudaTributo {
  tributo: string,
  insoluto: number,
  emision: number,
  reajuste: number,
  interes: number,
  deudaTotal: number,
  totalAgregadoCarrito: number,
  agregadoCarro: boolean
}

export interface DeudaPeriodo {
  periodo: number,
  insoluto: number,
  emision: number,
  reajuste: number,
  interes: number,
  deudaTotal: number,
  totalAgregadoCarrito: number,
  agregadoCarro: boolean
}

export interface DeudaFiltrada {
  deuda_id: number,
  periodo: number,
  tributo: string,
  referencia: string,
  cuota: string,
  deuda: number,
  descuento: number,
  deudaSelect: number,
}


@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
  providers: [ConsultasService]
})


export class RecordComponent implements OnInit {

  @Input() record$ = new Subject()

  puntosEnProcesoTotal : number=0;
  puntosFirmesTotal : number=0;

  opcionFiltro: number = 1;
  totalCarrito: number = 0;
  titleDetalle: string = ''
  recuperando: boolean = true

  recordInfractor: RecordInfractor[] = [];
  recordVehiculo: RecordVehiculo[] = []
  tipoRecord: string = '';

  //Genera displayedColumns para la tabla de RecordVehiculo
  //displayedColumnsVehiculo: string[] = ['nroPapeleta', 'fechaInfraccion', 'tipoInfraccion', 'lugarInfraccion', 'montoMulta', 'estado', 'imagen'];

  displayedColumnsVehiculo: string[] = ['item', 'nroPapeleta', 'fechaInfraccion', 'personaInfractorId', 'nombreInfractor', 'infraccion', 'numeroResolucion', 'montoMulta', 'estadoPapeleta'];

  periodosDeuda: DeudaPeriodo[] = []
  tributosDeuda: DeudaTributo[] = []
  deudaFiltrada: DeudaFiltrada[] = []

  estadoCuenta: EstadoCuenta[] = []
  totaldeuda: number = 0;
  suscription: Subscription;




  constructor(
    private router: Router,
    public estadoCuentaPublicService: EstadoCuentaPublicService,
    private consultasService: ConsultasService
  ) {

  }

  ngOnDestroy(): void {
    this.suscription.unsubscribe()
  }

  ngOnInit() {

    this.suscription=this.record$.subscribe(

      ()=>{
        this.record()        
      }
    )
  }

  record()
  {
    this.totaldeuda=0
    this.opcionFiltro = 1;
    this.totalCarrito = 0;
    this.titleDetalle = ''
    this.recuperando = true  
    this.recordInfractor = [];
    this.recordVehiculo = []
    this.tipoRecord = '';
    this.periodosDeuda = []
    this.tributosDeuda = []
    this.deudaFiltrada = []  
    this.estadoCuenta = []    

    this.puntosEnProcesoTotal =0
    this.puntosFirmesTotal =0
    


    // this.estadoCuentaPublicService.contriConsultar.persona_id = 210882
    // this.estadoCuentaPublicService.placa='M73486'

    // if (!this.estadoCuentaPublicService.contriConsultar.persona_id && !this.estadoCuentaPublicService.placa) {
    //   this.router.navigate(['/consulta']);
    //   return
    // }
    //console.log('record......')

    //Buscamos el Estado de Cuenta   
    if (this.estadoCuentaPublicService.contriConsultar.personaId) {
      //REcord de infractor
      this.tipoRecord = 'RECORD DE CONDUCTOR'

      this.consultasService.recordInfractor$(this.estadoCuentaPublicService.contriConsultar.personaId).subscribe(
        {
          next: (data) => {
            this.recordInfractor = data
          },

          complete: () => {
            this.recuperando = false

            if (this.recordInfractor.length > 0) {
              this.estadoCuentaPublicService.contriConsultar.contribuyente = this.recordInfractor[0].nombreInfractor

              this.recordInfractor.forEach(
                (data) => {
                  this.totaldeuda = this.totaldeuda + data.montoMulta

                  this.puntosEnProcesoTotal += data.puntosEnProc
                  this.puntosFirmesTotal += data.puntosFirmes

                }
              )



            }
          }
        }


      )

    }
    else {
      //REcord de vehículo
      //console.log('record placa')

      this.tipoRecord = 'RECORD DE VEHÍCULO'

      this.consultasService.recordVehiculo$(this.estadoCuentaPublicService.placa).subscribe(
        {
          next: (data) => {
            this.recordVehiculo = data
          },

          complete: () => {
            this.recuperando = false

            if (this.recordVehiculo.length > 0) {
               this.estadoCuentaPublicService.contriConsultar.contribuyente = this.estadoCuentaPublicService.placa 

               this.recordVehiculo.forEach(
                 (data)=>{
                   this.totaldeuda = this.totaldeuda + data.montoMulta
                 }
               )
              
              }
          }
        }

      )
    }

  }



  onMostrarDeuda(valor: any) {
    //
    var deudaFiltrada: DeudaFiltrada

    this.deudaFiltrada = []

    if (this.opcionFiltro == 1) {
      //Por tributo    

      let tributo: string
      tributo = valor

      this.titleDetalle = 'DETALLE TRIBUTO ' + tributo

      this.estadoCuenta.map(
        (data) => {
          if (data.concepto == tributo) {
            //
            deudaFiltrada = {
              deuda_id: data.deudaId,
              periodo: data.anio,
              tributo: data.concepto,
              referencia: data.descripcion,
              cuota: data.cuotas,
              deuda: Number((data.insoluto).toFixed(2)) + Number((data.emision).toFixed(2)) + Number((data.reajuste).toFixed(2)) + Number((data.interes).toFixed(2)),
              descuento: data.insoluto,
              deudaSelect: 0
            }

            if (data.agregadoCarrito) {
              deudaFiltrada.deudaSelect = deudaFiltrada.deuda

            }

            this.deudaFiltrada.push(deudaFiltrada)

          }

        }
      )

    }
    else if (this.opcionFiltro == 2) {
      //Por periodo
      let periodo: Number
      periodo = Number(valor)

      this.titleDetalle = 'DETALLE PERIODO ' + periodo.toString()

      this.estadoCuenta.map(
        (data) => {

          if (data.anio == periodo) {
            //
            deudaFiltrada = {
              deuda_id: data.deudaId,
              periodo: data.anio,
              tributo: data.concepto,
              referencia: data.descripcion,
              cuota: data.cuotas,
              deuda: Number((data.insoluto).toFixed(2)) + Number((data.emision).toFixed(2)) + Number((data.reajuste).toFixed(2)) + Number((data.interes).toFixed(2)),
              descuento: data.insoluto,
              deudaSelect: 0 //
            }

            if (data.agregadoCarrito) {
              deudaFiltrada.deudaSelect = deudaFiltrada.deuda
            }

            this.deudaFiltrada.push(deudaFiltrada)

          }

        }
      )
    }

    else {
      alert('Error grave, opción no válida')
    }
  }


  //Gestionar agregar carro de compras, por tributo o periodo
  onAgregarCarrito(filtro: number, indice: number, accion: boolean) {

    this.estadoCuenta.map(
      (data) => {

        if (filtro == 1) {//Por Tributo
          if (data.concepto == this.tributosDeuda[indice].tributo) {
            this.onGestionDeuda(data.deudaId, accion)
          }
        }
        else {
          //Por Periodo
          if (data.anio == this.periodosDeuda[indice].periodo) {
            this.onGestionDeuda(data.deudaId, accion)
          }

        }

      }
    )

  }


  onGestionDeuda(deuda_id: number, agregarCarrito: boolean) {

    //Marcamos la deuda en <estadoCuenta>
    let indice: number = -1
    indice = this.estadoCuenta.findIndex((data) => data.deudaId == deuda_id)

    if (indice < 0) {
      alert('ERROR INDICE NO ENONTRADO')
      return
    }

    //Solo continuamos si se desea cambiar el estado <agregarCarrito> de la deuda
    if (this.estadoCuenta[indice].agregadoCarrito == agregarCarrito) return


    this.estadoCuenta[indice].agregadoCarrito = agregarCarrito


    //Gestionamos los filtros
    //Buscamos el indice en <tributosDeuda>


    //Tributo
    let indiceFiltro: number;
    indiceFiltro = this.tributosDeuda.findIndex((data => data.tributo == this.estadoCuenta[indice].concepto))

    if (indiceFiltro < 0) {
      alert('error grave, no existe indiceFiltro TRIBUTO')
      return
    }


    if (agregarCarrito) {
      this.tributosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.tributosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) + Number(this.estadoCuenta[indice].totalDeuda1.toFixed(2))
    }
    else {
      this.tributosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.tributosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) - Number(this.estadoCuenta[indice].totalDeuda1.toFixed(2))
    }

    this.tributosDeuda[indiceFiltro].agregadoCarro = Number(this.tributosDeuda[indiceFiltro].deudaTotal.toFixed(2)) == Number(this.tributosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2))

    //Fin de tributo


    //Periodo
    indiceFiltro = this.periodosDeuda.findIndex((data => data.periodo == this.estadoCuenta[indice].anio))

    if (indiceFiltro < 0) {
      alert('error grave, no existe indiceFiltro PERIODO')
      return
    }

    if (agregarCarrito) {
      this.periodosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.periodosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) + Number(this.estadoCuenta[indice].totalDeuda1.toFixed(2))
    }
    else {
      this.periodosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.periodosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) - Number(this.estadoCuenta[indice].totalDeuda1.toFixed(2))
    }

    this.periodosDeuda[indiceFiltro].agregadoCarro = Number(this.periodosDeuda[indiceFiltro].deudaTotal.toFixed(2)) == Number(this.periodosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2))

    //Fin de periodo



    if (agregarCarrito) {
      this.totalCarrito = Number(this.totalCarrito.toFixed(2)) + Number(this.estadoCuenta[indice].totalDeuda1.toFixed(2))
    }
    else {
      this.totalCarrito = Number(this.totalCarrito.toFixed(2)) - Number(this.estadoCuenta[indice].totalDeuda1.toFixed(2))
    }

    //deudaFiltrada
    let indice2: number


    //Si la vista de filtro esta activa
    indice2 = this.deudaFiltrada.findIndex((data) => {
      return data.deuda_id == deuda_id
    })

    if (indice2 >= 0) {
      //Cambiamos a las deudas filtradas de la vista
      if (agregarCarrito)
        this.deudaFiltrada[indice2].deudaSelect = this.deudaFiltrada[indice2].deuda
      else
        this.deudaFiltrada[indice2].deudaSelect = 0

    }

  }


  onMarcarWhatSApp() {

    let fonoActivo: string = ''

    // this.router.navigate(['whatsapp://send?text=HolaMundodesdesatcajamrca&phone=+51976333485']);
    this.consultasService.fonodisponible(1).subscribe(
      (data) => {

        fonoActivo = data.nro_fono;

      },

      (error) => {
        //Telefono por defecto
        console.log(error)
      },

      () => {
        if (!fonoActivo || fonoActivo == '') {
          //Telefono por defecto
          fonoActivo = '51976686810'
        }


        // fonoActivo = '51976333485'


        let url: String
        let texto: string

        // texto = 'Buenas tarde Necesito consultar la deuda con Código de Contribuyente: ' + this.estadoCuentaPublicService.contriConsultar.persona_id.toString() + ', ' + this.estadoCuentaPublicService.contriConsultar.contribuyente + ' Total de la deuda: S/ ' + this.totaldeuda.toLocaleString('en') + '   Total Seleccionado: S/' + this.totalCarrito.toLocaleString('en')

        
        if (this.estadoCuentaPublicService.contriConsultar.personaId) {
          //REcord de infractor
          texto = 'Buenas tarde Necesito consultar la deuda de Infracciones de Tránsito '+this.tipoRecord+' con Código de infractor : ' + this.estadoCuentaPublicService.contriConsultar.personaId+' '+ this.estadoCuentaPublicService.contriConsultar.contribuyente + ' Total de la deuda: S/ ' + this.totaldeuda.toLocaleString('en') 
          
        }
        else
          {
            //REcord de Vehiculo
            texto = 'Buenas tarde Necesito consultar la deuda de Infracciones de Tránsito '+this.tipoRecord+' con Placa: ' + this.estadoCuentaPublicService.placa + ' Total de la deuda: S/ ' + this.totaldeuda.toLocaleString('en') 

          }
          url = `https://web.whatsapp.com/send?phone=${fonoActivo}&text=${encodeURIComponent(texto)}`;


        window.open(url.toString(), '_blank');

      }

    )

  }

  verImagen(data: RecordInfractor) {
    alert(data.nroPapeleta)
  }

  get deudaTotal() {
    return this.totaldeuda
  }  
    
    exportToPdf() {
      const doc = new jsPDF();
      const table = document.getElementById('IdRecordVehiculo') as HTMLElement;
      const options = {
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        html: table,
        x: 10,
        y: 10
      };

      //autoTable(doc, options);
      //doc.autoTable(options);
      //doc.autoTable({ html: '#' + 12 });
      doc.save('record_vehiculo.pdf');
    }

  

}
