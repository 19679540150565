import { Injectable } from '@angular/core';
import { RecordVehiculo,RecordInfractor,TdTipoTramite, Infractor, MpActualizaDatosWeb } from 'src/app/modelos/consultas/consultas';
import { EstadoCuenta, BusquedaContri,TdExpedienteWeb } from '../../modelos/consultas/consultas';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { apiMainService } from '../api.main.service';

import {CfLocales,CfHome,CfCarousel,CfCardsHome,CfImagesCardsHome} from '../../modelos/config/config'
import { HttpParams } from '@angular/common/http';

export class ConfigService extends apiMainService {

  //Locales
  getLocales$(): Observable<CfLocales[]> {
    return this.http.get<CfLocales[]>(this.url_api + '/api/web/CfLocales/', { headers: this.httpOptions.headers,withCredentials: false });    
  }

  //OCfiguración del home
  getHome$(): Observable<CfHome[]> {

    return this.http.get<CfHome[]>(this.url_api + '/api/web/CfHomes/', { headers: this.httpOptions.headers,withCredentials: false });    

  }

  //Carousel, tipo tema carousel del home
  getcarousel$(): Observable<CfCarousel[]> {
    return this.http.get<CfCarousel[]>(this.url_api + '/api/web/CfCarousels/', { headers: this.httpOptions.headers,withCredentials: false });    
  }

  //cardshome
  getCardshome$(): Observable<CfCardsHome[]> {
    return this.http.get<CfCardsHome[]>(this.url_api + '/api/web/CfCardsHomes/', { headers: this.httpOptions.headers,withCredentials: false });    
  }

  // images_cards_home
  getImagesCardsHome$(id_card : number) : Observable<CfImagesCardsHome[]>
  {    

    const parametros = new HttpParams() 
    .set('id', id_card.toString())
    // .set('idContribuyente', idContribuyente.toString());
    // return this.http.get<CfImagesCardsHome[]>(this.url_api + '/web/config/imagescardshome/', {headers: this.httpOptions.headers, params : parametros}); 
    return this.http.get<CfImagesCardsHome[]>(this.url_api + '/api/web/CfImagesCardsHomes/'+id_card.toString() , {headers: this.httpOptions.headers, params : parametros}); 
  }

  





  

  

  // //
  // postExpedienteWeb$(expediente : TdExpedienteWeb) : Observable<TdExpedienteWeb> {
  //   return this.http.post<TdExpedienteWeb>(this.url_api + '/tdexpedienteweb/',expediente,{headers : this.httpOptions.headers,withCredentials: false} )
  // }

  // //
  // postActualziaDatos$(datos : MpActualizaDatosWeb) : Observable<MpActualizaDatosWeb> {
  //   return this.http.post<MpActualizaDatosWeb>(this.url_api + '/mpactualizadatosweb/',datos,{headers : this.httpOptions.headers,withCredentials: false} )
  // } 


  // fonodisponible(idunidad : number):Observable<any>
  // {    
  //   let datos ={
  //     idunidad : idunidad,      
  //   }
    
  //   return this.http.post<any>(this.url_api + '/fonodisponible/',datos , { headers: this.httpOptions.headers });
  // }


  // busquedaInfractor(tipo_busqueda : number, valor : string  ):Observable<Infractor[]>
  // {

  //   let datos ={
  //     tipobusqueda          : tipo_busqueda, //Búsqueda por Apellidos y nombres.
  //     valor                 : valor
  //   }
    
  //   return this.http.post<Infractor[]>(this.url_api + '/busquedainfractor/',datos, { headers: this.httpOptions.headers });

  // }


  // recordInfractor$( idPersona : number ):Observable<RecordInfractor[]>
  // {

  //   let datos ={
  //     personaid          : idPersona,
  //   }
    
  //   return this.http.post<RecordInfractor[]>(this.url_api + '/recordInfractor/',datos, { headers: this.httpOptions.headers });

  // }


  // recordVehiculo$( placa : string ):Observable<RecordVehiculo[]>
  // {

  //   let datos ={
  //     placa          : placa,
  //   }
    
  //   return this.http.post<RecordVehiculo[]>(this.url_api + '/recordVehiculo/',datos, { headers: this.httpOptions.headers });

  // }


  

  // busquedaContri(tipo_busqueda : number,tipo_doc_identidad_id : number, valor : string  ):Observable<BusquedaContri[]>
  // {
  //   /*
  //   Realiza bpusquedas de contribuyente por @tipo_busqueda,
  //   @tipo_busqueda=1, por DNI o RUC.
  //   @tipo_busqueda=2, por Código de contribuyente
  //   @tipo_busqueda=3, por Apellidos y nombres o razón Social.
  //   */

  //   let datos ={
  //     tipobusqueda          : tipo_busqueda,
  //     tipodocidentidadid    : tipo_doc_identidad_id,
  //     valor                 : valor
  //   }
    
  //   return this.http.post<BusquedaContri[]>(this.url_api + '/busquedacontri/',datos , { headers: this.httpOptions.headers });

  // }

  // estadoCuenta(idPersona : number):Observable<EstadoCuenta[]>
  // {

  //   let datos ={
  //     idpersona : idPersona,
  //   }
    
  //   return this.http.post<EstadoCuenta[]>(this.url_api + '/deudastributarias/',datos , { headers: this.httpOptions.headers });     


  // }

  // resetPassword(email : string, password : string ):Observable<boolean>
  // {
  //   password=crypto.SHA512(email + password).toString();

  //   let datos ={
  //     email : email,
  //     password: password
  //   }    

  //   //Cambiar la contraseña
  //   return this.http.post<boolean>(this.url_api + '/resetpassword/',datos, { headers: this.httpOptions.headers });     


  // }
}
