import { Component, OnInit, ɵConsole, Input } from '@angular/core';
import { ConsultasService } from 'src/app/servicios/consultas/consultas.service';
import { EstadoCuenta, DeudaFiltrada } from '../../../modelos/consultas/consultas';
import { FormControl, Validators } from '@angular/forms';
import { EstadoCuentaPublicService } from 'src/app/servicios/public/estadoCuentaPublic.service';
import { $ } from 'protractor';
import { BrowserStack } from 'protractor/built/driverProviders';
import { Router } from '@angular/router';
import { url } from 'inspector';
import { stringify } from 'querystring';
import { MatDialog } from '@angular/material/dialog';

import { ListDeudasComponent } from './listDeudas/listDeudas.component'
import { Subject, Subscription } from 'rxjs';
import { Console } from 'console';
import { ECDH } from 'crypto';


export interface DeudaAgregada {
  deudaId: number,
  totalDeuda: number,
  descuento: number,
  totalAgregado: number

}

export interface DeudaTributo {
  tributo: string,
  insoluto: number,
  emision: number,
  reajuste: number,
  interes: number,
  descuento: number,
  deudaTotal: number,
  totalAgregadoCarrito: number,
  activardescuento: number, //1 o 0
  agregadoCarro: boolean
}

export interface DeudaPeriodo {
  periodo: number,
  insoluto: number,
  emision: number,
  reajuste: number,
  interes: number,
  descuento: number,
  deudaTotal: number,
  totalAgregadoCarrito: number,
  activardescuento: number, //1 o 0
  agregadoCarro: boolean
}



@Component({
  selector: 'app-estadoCuenta',
  templateUrl: './estadoCuenta.component.html',
  styleUrls: ['./estadoCuenta.component.scss'],
  providers: [ConsultasService]
})

export class EstadoCuentaComponent implements OnInit {

  //genra deuda
  

  @Input() estadoCuenta$ = new Subject<number>();

  suscription: Subscription

  public opcionFiltro: number = 1;
  totalCarrito: number = 0;
  totalSeleccion: number = 0;
  titleDetalle: string = ''
  recuperando: boolean = true

  periodosDeuda: DeudaPeriodo[] = []
  tributosDeuda: DeudaTributo[] = []
  deudaFiltrada: DeudaFiltrada[] = []

  //Gestionar datos agregados a carrito
  deudaAgregada: DeudaAgregada[] = []

  estadoCuenta: EstadoCuenta[] = []
  totaldeuda: number = 0;

  gestionDeuda$: Subject<DeudaFiltrada[]> = new Subject<DeudaFiltrada[]>()


  descargarEC() {

    let url: String
    url = `https://190.116.36.140:8080/estadocuentasatc/${this.estadoCuentaPublicService.contriConsultar.personaId}`

    window.open(url.toString(), '_blank');

  }


  openDetalle() {

    const dialogRef = this.dialog.open(ListDeudasComponent,
      {
        data: {
          subject$: this.gestionDeuda$,
          title: this.titleDetalle
        }
      }
    );

    //NOs suscribimos a los cambios de agrgar o quitar del carro
    dialogRef.componentInstance.onCarrito.subscribe(
      (data: any) => {

        this.onGestionDeuda(data.id, data.accion)

        this.gestionDeuda$.next(this.deudaFiltrada)

      }
    )

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });

  }

  constructor(
    public dialog: MatDialog,

    private router: Router,
    public estadoCuentaPublicService: EstadoCuentaPublicService,
    private consultasService: ConsultasService
  ) {

  }

  ngOnDestroy(): void {
    this.suscription.unsubscribe()
  }

  ngOnInit() {

    this.suscription = this.estadoCuenta$.subscribe(
      (data) => {
        this.consultarEC(data)
      }
    )

  }

  consultarEC(id: number) {

    //Inicamos all
    this.opcionFiltro = 1;
    this.totalCarrito = 0;
    this.totalSeleccion = 0;
    this.titleDetalle = ''
    this.recuperando = true


    this.periodosDeuda = []
    this.tributosDeuda = []
    this.deudaFiltrada = []

    this.estadoCuenta = []
    this.totaldeuda = 0;
    ////

    this.estadoCuentaPublicService.contriConsultar.personaId = id

    if (!this.estadoCuentaPublicService.contriConsultar.personaId) {
      this.router.navigate(['/consulta']);
      return
    }
    //Buscamos el Estado de Cuenta

    if (this.estadoCuentaPublicService.contriConsultar.personaId) {

      this.consultasService.estadoCuenta(this.estadoCuentaPublicService.contriConsultar.personaId).subscribe(
        {
          next: (data) => {

            this.estadoCuenta = data

            

          },

          error: (err) => {
            alert('Error de Conexión: ' + err)
            this.recuperando = false
          },

          complete: () => {

            this.recuperando = false

            //TOTALES
            if (!this.estadoCuenta) return

            this.estadoCuenta.map(
              (data, index) => {

                // this.estadoCuenta[index].totalDeuda = Number((data.insoluto).toFixed(2)) + Number((data.emision).toFixed(2)) + Number((data.Reajuste).toFixed(2)) + Number((data.interes).toFixed(2))                

                this.totaldeuda += Number(this.estadoCuenta[index].totalDeuda1.toFixed(2))

                //por Periodo
                if (!this.periodosDeuda.find((dataPeriodo) => { return dataPeriodo.periodo == data.anio })) {
                  //no existe Periodo
                  let d: DeudaPeriodo
                  d = {
                    periodo: data.anio,
                    insoluto: 0,
                    emision: 0,
                    reajuste: 0,
                    interes: 0,
                    descuento: 0,
                    deudaTotal: 0,
                    totalAgregadoCarrito: 0,
                    activardescuento: 0,
                    agregadoCarro: false
                  }

                  this.periodosDeuda.push(d)
                }
                //



                //por Tributos
                if (!this.tributosDeuda.find((dataTributo) => { return dataTributo.tributo == data.concepto })) {
                  //no existe Tributo
                  let d: DeudaTributo
                  d = {
                    tributo: data.concepto,
                    insoluto: 0,
                    emision: 0,
                    reajuste: 0,
                    interes: 0,
                    descuento: 0,
                    deudaTotal: 0,
                    totalAgregadoCarrito: 0,
                    activardescuento: 0,
                    agregadoCarro: false
                  }

                  this.tributosDeuda.push(d)
                }
                //
              }

              )

            //Totales por filtros
            this.estadoCuenta.map(

              (data, indexEC) => {

                this.estadoCuenta[indexEC].agregadoCarrito = false;



                //Sumamos lo montos agrupados.

                //Total de deuda por Periodo
                this.periodosDeuda.find((dataPeriodo, index) => {


                  if (dataPeriodo.periodo == data.anio) {

                    this.periodosDeuda[index].insoluto = Number((this.periodosDeuda[index].insoluto).toFixed(2)) + Number((data.insoluto).toFixed(2))
                    this.periodosDeuda[index].emision = Number((this.periodosDeuda[index].emision).toFixed(2)) + Number((data.emision).toFixed(2))
                    this.periodosDeuda[index].reajuste = Number((this.periodosDeuda[index].reajuste).toFixed(2)) + Number((data.reajuste).toFixed(2))
                    this.periodosDeuda[index].interes = Number((this.periodosDeuda[index].interes).toFixed(2)) + Number((data.interes).toFixed(2))

                    this.periodosDeuda[index].descuento = Number((this.periodosDeuda[index].descuento).toFixed(2)) + Number((data.descuento).toFixed(2))

                    this.periodosDeuda[index].deudaTotal = Number(this.periodosDeuda[index].deudaTotal.toFixed(2)) + Number(this.estadoCuenta[indexEC].totalDeuda1.toFixed(2))

                    return true

                  }
                  else
                    return false


                })
                //Fin Total de deuda por Periodo

                



                //Total de deuda por tributo
                this.tributosDeuda.find((dataTributo, index) => {

                  if (dataTributo.tributo == data.concepto) {

                    this.tributosDeuda[index].insoluto = Number((this.tributosDeuda[index].insoluto).toFixed(2)) + Number((data.insoluto).toFixed(2))
                    this.tributosDeuda[index].emision = Number((this.tributosDeuda[index].emision).toFixed(2)) + Number((data.emision).toFixed(2))
                    this.tributosDeuda[index].reajuste = Number((this.tributosDeuda[index].reajuste).toFixed(2)) + Number((data.reajuste).toFixed(2))
                    this.tributosDeuda[index].interes = Number((this.tributosDeuda[index].interes).toFixed(2)) + Number((data.interes).toFixed(2))

                    this.tributosDeuda[index].descuento = Number((this.tributosDeuda[index].descuento).toFixed(2)) + Number((data.descuento).toFixed(2))

                    this.tributosDeuda[index].deudaTotal = Number(this.tributosDeuda[index].deudaTotal.toFixed(2)) + Number(this.estadoCuenta[indexEC].totalDeuda1.toFixed(2))
                    
                    return true
                  }
                  else
                    return false



                })
                //Fin //Total de deuda por tributo






              }
            )
          }
        }
      )
    }

    else {
      // alert('No se reconoce el CONTRIBUYENTE.')
    }
  }


  onMostrarDeuda(valor: any) {
    //
    var deudaFiltrada: DeudaFiltrada

    this.deudaFiltrada = []

    //console.log(this.opcionFiltro)

    if (this.opcionFiltro == 1) {
      //Por tributo    

      let tributo: string
      tributo = valor

      this.titleDetalle = 'Detalle Tributo ' + tributo

      this.estadoCuenta.map(
        (data) => {
          if (data.concepto == tributo) {
            //
            deudaFiltrada = {
              deudaId: data.deudaId,
              periodo: data.anio,
              tributo: data.concepto,
              referencia: data.descripcion,
              cuota: data.cuotas,
              deuda: Number((data.insoluto).toFixed(2)) + Number((data.emision).toFixed(2)) + Number((data.reajuste).toFixed(2)) + Number((data.interes).toFixed(2)),
              descuento: data.descuento,
              deudaSelect: 0
            }

            if (data.agregadoCarrito) {
              deudaFiltrada.deudaSelect = deudaFiltrada.deuda

            }

            this.deudaFiltrada.push(deudaFiltrada)

          }

        }
      )

    }
    else if (this.opcionFiltro == 2) {
      //Por periodo
      let periodo: Number
      periodo = Number(valor)

      this.titleDetalle = 'Detalle Periodo ' + periodo.toString()

      this.estadoCuenta.map(
        (data) => {

          if (data.anio == periodo) {
            //
            deudaFiltrada = {
              deudaId: data.deudaId,
              periodo: data.anio,
              tributo: data.concepto,
              referencia: data.descripcion,
              cuota: data.cuotas,
              deuda: Number((data.insoluto).toFixed(2)) + Number((data.emision).toFixed(2)) + Number((data.reajuste).toFixed(2)) + Number((data.interes).toFixed(2)),
              descuento: data.descuento,
              deudaSelect: 0 //
            }

            if (data.agregadoCarrito) {
              deudaFiltrada.deudaSelect = deudaFiltrada.deuda
            }

            this.deudaFiltrada.push(deudaFiltrada)

          }

        }
      )
    }

    else {
      alert('Error grave, opción no válida')
    }

    //Llamamos al Dialog.
    this.estadoCuentaPublicService.deudaFiltrada = this.deudaFiltrada
    this.openDetalle()

  }


  //Gestionar agregar carro de compras, por tributo o periodo
  onAgregarCarrito(filtro: number, indice: number, accion: boolean) {

    this.estadoCuenta.map(
      (data) => {

        if (filtro == 1) {//Por Tributo
          if (data.concepto == this.tributosDeuda[indice].tributo) {
            this.onGestionDeuda(data.deudaId, accion)
          }
        }
        else {
          //Por Periodo
          if (data.anio == this.periodosDeuda[indice].periodo) {
            this.onGestionDeuda(data.deudaId, accion)
          }

        }

      }
    )

  }

  onGestionDeuda(deuda_id: number, agregarCarrito: boolean) {

    //Marcamos la deuda en <estadoCuenta>
    let indice: number = -1
    indice = this.estadoCuenta.findIndex((data) => data.deudaId == deuda_id)
    

    if (indice < 0) {
      alert('ERROR INDICE NO ENONTRADO')
      return
    }

    //Solo continuamos si se desea cambiar el estado <agregarCarrito> de la deuda
    if (this.estadoCuenta[indice].agregadoCarrito == agregarCarrito) return

    this.estadoCuenta[indice].agregadoCarrito = agregarCarrito

    //Gestionamos los filtros
    //Buscamos el indice en <tributosDeuda>


    //Tributo
    let indiceFiltro: number;
    indiceFiltro = this.tributosDeuda.findIndex((data => data.tributo == this.estadoCuenta[indice].concepto))

    if (indiceFiltro < 0) {
      alert('error grave, no existe indice Filtro TRIBUTO')
      return
    }


    if (agregarCarrito) {
      this.tributosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.tributosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) + Number((this.estadoCuenta[indice].totalDeuda1 - this.estadoCuenta[indice].descuento).toFixed(2))
    }
    else {
      this.tributosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.tributosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) - Number((this.estadoCuenta[indice].totalDeuda1 - this.estadoCuenta[indice].descuento).toFixed(2))
    }

    this.tributosDeuda[indiceFiltro].agregadoCarro = Number((this.tributosDeuda[indiceFiltro].deudaTotal - this.tributosDeuda[indiceFiltro].descuento).toFixed(2)) == Number(this.tributosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2))

    //Fin de tributo


    //Periodo
    indiceFiltro = this.periodosDeuda.findIndex((data => data.periodo == this.estadoCuenta[indice].anio))

    if (indiceFiltro < 0) {
      alert('error grave, no existe indice Filtro PERIODO')
      return
    }

    if (agregarCarrito) {
      this.periodosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.periodosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) + Number((this.estadoCuenta[indice].totalDeuda1 - this.estadoCuenta[indice].descuento).toFixed(2))
    }
    else {
      this.periodosDeuda[indiceFiltro].totalAgregadoCarrito = Number(this.periodosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2)) - Number((this.estadoCuenta[indice].totalDeuda1 - this.estadoCuenta[indice].descuento).toFixed(2))
    }

    this.periodosDeuda[indiceFiltro].agregadoCarro = Number((this.periodosDeuda[indiceFiltro].deudaTotal - this.periodosDeuda[indiceFiltro].descuento).toFixed(2)) == Number(this.periodosDeuda[indiceFiltro].totalAgregadoCarrito.toFixed(2))
    //Fin de periodo



    let activarDEscuento: number;
    activarDEscuento = this.estadoCuenta[indice].activardescuento || 0


    let deudaAgregada: DeudaAgregada

    deudaAgregada = {
      deudaId: this.estadoCuenta[indice].deudaId,
      totalDeuda: this.estadoCuenta[indice].totalDeuda1,
      descuento: this.estadoCuenta[indice].descuento,
      totalAgregado: 0//SIN ACCIONES POR AHORA
    }

    let indiceAgregado: number;
    indiceAgregado = this.deudaAgregada.findIndex(d => d.deudaId == this.estadoCuenta[indice].deudaId)
    
    //Borramos si existiese 
    if (indiceAgregado >= 0) this.deudaAgregada.splice(indiceAgregado,1); //Eliminamos
    

    if (agregarCarrito) {
      //Borramos si existiese 
      //if (indiceAgregado >= 0) this.deudaAgregada.splice(indiceAgregado,1); //Eliminamos 
      this.deudaAgregada.push(deudaAgregada);


      //this.totalCarrito   = Number(this.totalCarrito.toFixed(2)) + Number( ( deudaAgregada.totalAgregado).toFixed(2) );
      //this.totalSeleccion = Number(this.totalCarrito.toFixed(2)) + Number((deudaAgregada.totalDeuda).toFixed(2));

      //this.totalCarrito   = Number(this.totalCarrito.toFixed(2))    + Number(  (this.estadoCuenta[indice].total_deuda - this.estadoCuenta[indice].descuento).toFixed(2))
      //this.totalSeleccion = Number(this.totalSeleccion.toFixed(2))  + Number(  (this.estadoCuenta[indice].total_deuda ))
    }
    // else {

      // if (indiceAgregado<0) 
      // {
      //   alert('Error X001-V2, NO se puede obtener acceso al indice')
      //   return
      // }

      //if (indiceAgregado>=0) this.deudaAgregada.splice(indiceAgregado); //Eliminamos

      //this.totalCarrito   = Number(this.totalCarrito.toFixed(2))    - this.deudaAgregada[indiceAgregado].totalDeuda
      //this.totalSeleccion = Number(this.totalSeleccion.toFixed(2))  - this.deudaAgregada[indiceAgregado].totalAgregado

      //this.totalCarrito   = Number(this.totalCarrito.toFixed(2))    - Number( (this.estadoCuenta[indice].total_deuda - this.estadoCuenta[indice].descuento).toFixed(2))
      //this.totalSeleccion = Number(this.totalSeleccion.toFixed(2))  - Number(  (this.estadoCuenta[indice].total_deuda ))
    // }

    //deudaFiltrada
    let indice2: number

    //Si la vista de filtro esta activa
    indice2 = this.deudaFiltrada.findIndex((data) => {
      return data.deudaId == deuda_id
    })

    if (indice2 >= 0) {
      //Cambiamos a las deudas filtradas de la vista
      if (agregarCarrito)
        this.deudaFiltrada[indice2].deudaSelect = this.deudaFiltrada[indice2].deuda
      else
        this.deudaFiltrada[indice2].deudaSelect = 0

    }
    //
    this.onTotales()

  }

  //Verificamos si se ha seleccinoado todo el predial del perido actual.
  onTotales() {

    let activarDescuento: number = 0

    //Solo se activa el decuento si no existen deudas prediales de año en curso.
    //buscamos si existe alguno sin seleccionar predial periodo Actual
    if (this.estadoCuenta.findIndex(ec => ec.anio == 2023 && ec.conceptoId == 1 && ec.agregadoCarrito == false) < 0)
      activarDescuento = 1

    //Set el descuento para cada deuda.
    this.estadoCuenta.map(ec => ec.activardescuento = activarDescuento)

    //console.log('LE CORRESPONDE EL DESCUENTO ',this.deudaAgregada)
    this.totalCarrito = 0
    this.totalSeleccion = 0

    //Total seleccionado
    this.deudaAgregada.map(d => this.totalSeleccion += d.totalDeuda)

    if (activarDescuento == 1) {
      //Con descuento.
      this.deudaAgregada.map(d => this.totalCarrito += (d.totalDeuda - d.descuento))

    }
    else {
      //SIN descuento.
      this.deudaAgregada.map(d => this.totalCarrito += d.totalDeuda)

    }



  }

  onMarcarWhatSApp() {

    let fonoActivo: string = ''

    // this.router.navigate(['whatsapp://send?text=HolaMundodesdesatcajamrca&phone=+51976333485']);
    this.consultasService.fonodisponible(1).subscribe(
      (data) => {

        fonoActivo = data.nro_fono;        

      },

      (error) => {
        //Telefono por defecto
        console.log(error)
      },

      () => {
        if (!fonoActivo || fonoActivo == '') {
          //Telefono por defecto
          fonoActivo = '51976686810'
        }

        // fonoActivo = '51976333485'
        let url: String
        let texto: string

        texto = 'Buenas tarde Necesito consultar la deuda con Código de Contribuyente: ' + this.estadoCuentaPublicService.contriConsultar.personaId.toString() + ', ' + this.estadoCuentaPublicService.contriConsultar.contribuyente + ' Total de la deuda: S/ ' + this.totaldeuda.toLocaleString('en') + '   Total Seleccionado: S/' + this.totalCarrito.toLocaleString('en')

        //url = `http://wa.me/${fonoActivo}/?text=${texto}`       

        
         url = `https://web.whatsapp.com/send?phone=${fonoActivo}&text=${encodeURIComponent(texto)}`;

        window.open(url.toString(), '_blank');

      }

    )

  }

}


