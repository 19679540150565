import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 

import { map, catchError, tap } from 'rxjs/operators'; 


@Injectable()

// tslint:disable-next-line: class-name
export class apiMainService {
  
  // tslint:disable-next-line: variable-name
  //public url_api = 'https://190.116.36.140:8080'; //Node
  
  public url_api = 'https://190.116.36.138:88'; //C#
  
  //public url_api = 'http://172.26.10.4:7273'; //local
  
  public httpOptions = { 
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json' 
      //'Content-Type': 'application/json;charset=UTF-8'
      
    })
    }; 
      // https://consultasweb.satcajamarca.gob.pe/
  constructor(public http: HttpClient) { }

}
