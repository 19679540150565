export class CfLocales {
	idLocal	     : number;
	title			 : string;
	direccion		 : string;
	horario			 : string;
	titleContenido	 : string;
	contenido		 : string;
	imagen			 : string;
	mapa			 : string;
	link			 : string;
	activo			 : boolean;

	url				  : any

}

export class CfCarousel {
	imagen			 : string;
	title			 : string;
	subtitle		 : string;
	link			 : string;
	orden			 : number;
	activo			 : boolean;

}

export class CfHome {
	headerTypes			: string;
	imagenHome 		: string;	
	title				: string;
	subtitle			: string;
	bgImageAnimate		: boolean;
	contentOffsetToTop	: boolean;
	isHomePage			: boolean;
	viewType			: string;
	viewCol			 	: number;
}


export class CfCardsHome {
	idCardsHome		: number;
	title			 	: string;
	subtitle		 	: string;
	subtitleIcon	 	: string;
	header			 	: string;
	headerIcon		 	: string;
	contenido		 	: string;
	contenidoFooter	: string;
	footer			 	: string;
	footerIcon			: string;
	footerButtonText	: string;
	footerButtonIcon	: string;
	footerButtonLink	: string;
	propertyStatus		: string;
	
	youtubeId			: string;
	youtubeAutoplay	: boolean;
	youtubeHeight		: number;
	youtubeWidth		: number;
	youtubeOnly		: boolean;

	orden				: number;
	estado				: boolean;

	gallery				: CfImagesCardsHome[]

}


export class CfImagesCardsHome {
	idimagen		: number;
	idCardsHome	: number;
	imagen			: string;
	activo			: boolean;
}




