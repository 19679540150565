import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../../app.service'; 
import { isNumber } from 'util';
import { match } from 'assert';
import { EstadoCuentaPublicService } from 'src/app/servicios/public/estadoCuentaPublic.service';
import { Router } from '@angular/router';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';


@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss']
})


export class PropertiesSearchComponent implements OnInit {

  //EL id de las opcioens de búsqueda en [CONSULTAS]
  private idtipoBusquedaSelected : number=0

  titleNumerico : string
  //Form
  formNUmerico : FormControl;
  
  //Fnde Form

  @Input() variant:number = 1;
  @Input() vertical:boolean = false;
  @Input() searchOnBtnClick:boolean = false;
  @Input() removedSearchField:string;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public form: FormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features = [];

  
  constructor(
    

    private router : Router,
    public appService:AppService, 
    public fb: FormBuilder,
    private estadoCuentaPublicService: EstadoCuentaPublicService) {

    this.formNUmerico = new FormControl('',[ Validators.required,Validators.minLength(1)])

    this.formNUmerico.valueChanges.subscribe(
      (data: string)=>{

        //Verificamos que sea number
        if (isNaN( Number( data) ))
        {
            //No es un parámetro tipo number
            this.titleNumerico='SIN DEFINIR'
            this.idtipoBusquedaSelected=0
            return
        }
        
        
        if( !data || data.length==0)
        {
          //Vacio
          this.titleNumerico=''

        }
        else if(data.length<8)
        {
          //Búsqueda por Cödigo
          this.titleNumerico='Cosulta por Código'
          this.idtipoBusquedaSelected=3

        }
        else if(data.length == 8)
          {
            //Búsqueda por DNI
            this.titleNumerico='Cosulta por DNI'
            this.idtipoBusquedaSelected=1

          }
        else if(data.length < 11)
        {
          //sIN DEFINIR
          this.titleNumerico='SIN DEFINIR'
        }

        else
        {
          //Búsqueda es por RUC
          this.titleNumerico='Cosulta por RUC'
          this.idtipoBusquedaSelected=2

        }

        
      }
    )

  }

  onConsultar(opcion: number, valor : string)
  {
    //
    switch (opcion) {
      case 1:
        //Consulta Numperica
        this.estadoCuentaPublicService.isConsultaRapida = true
        this.estadoCuentaPublicService.idtipoBusquedaSelected=this.idtipoBusquedaSelected
        this.estadoCuentaPublicService.valorConsultaRapida=valor
        break;
      case 2:
        //Apellidos / razón Social
        this.estadoCuentaPublicService.isConsultaRapida = true
        this.estadoCuentaPublicService.idtipoBusquedaSelected=5
        this.estadoCuentaPublicService.valorConsultaRapida=valor

        break;
      case 3:
        //Placa
        this.estadoCuentaPublicService.isConsultaRapida = true
        this.estadoCuentaPublicService.idtipoBusquedaSelected=7        
        this.estadoCuentaPublicService.valorConsultaRapida=valor

    
      default:
        break;
    }
    ////

    //Ruoter

    this.router.navigate(['/consultas'])

    
    

  }

  ngOnInit() {
   

    if(this.vertical){
      this.showMore = true;
    };
    this.propertyTypes = this.appService.getPropertyTypes();
    this.propertyStatuses = this.appService.getPropertyStatuses();
    this.cities = this.appService.getCities();
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();
    this.form = this.fb.group({
      propertyType: null,
      propertyStatus: null, 
      price: this.fb.group({
        from: null,
        to: null 
      }),
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: null 
      }),
      bathrooms: this.fb.group({
        from: null,
        to: null 
      }),
      garages: this.fb.group({
        from: null,
        to: null 
      }),
      area: this.fb.group({
        from: null,
        to: null 
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: null 
      }),       
      features: this.buildFeatures()
    }); 

    this.onSearchChange.emit(this.form);
  }
 
  public buildFeatures() {
    const arr = this.features.map(feature => { 
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected
      });
    })   
    return this.fb.array(arr);
  }
  

  ngOnChanges(){ 
    if(this.removedSearchField){ 
      if(this.removedSearchField.indexOf(".") > -1){
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      } 
      else if(this.removedSearchField.indexOf(",") > -1){        
        let arr = this.removedSearchField.split(","); 
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);  
      }
      else{
        this.form.controls[this.removedSearchField].reset();
      }  
    }  
  }

  public reset(){     
    this.form.reset({ 
      propertyType: null,
      propertyStatus: null, 
      price: {
        from: null,
        to: null 
      },
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: {
        from: null,
        to: null 
      },
      bathrooms: {
        from: null,
        to: null 
      },
      garages: {
        from: null,
        to: null 
      },
      area: {
        from: null,
        to: null 
      },
      yearBuilt: {
        from: null,
        to: null 
      },       
      features: this.features    
    }); 
  }

  public search(){
    this.onSearchClick.emit(); 
  }

  public onSelectCity(){
    this.form.controls['neighborhood'].setValue(null, {emitEvent: false});
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }
  public onSelectNeighborhood(){
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }

  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }


}
